// Generated by Framer (21ec140)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import DLook from "https://framerusercontent.com/modules/OiCf4NNLLpuN6FqtIYsk/OuCfjB574sW5UoLzNCdh/Look3D.js";
import ImagensHero3D from "./dBA32XyOo";
const ImagensHero3DFonts = getFonts(ImagensHero3D);
const DLookFonts = getFonts(DLook);

const cycleOrder = ["wnjvv5_gQ"];

const serializationHash = "framer-y4Jtp"

const variantClassNames = {wnjvv5_gQ: "framer-v-1otbhbj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "wnjvv5_gQ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1otbhbj", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"wnjvv5_gQ"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-1nwzthi-container"} layoutDependency={layoutDependency} layoutId={"QglMHI4hv-container"}><DLook draggingOptions={{transition: {damping: 100, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 600, type: false}, withDragging: false, withSnapBack: false}} height={"100%"} id={"QglMHI4hv"} layoutId={"QglMHI4hv"} perspectiveOptions={{perspectiveValue: 3500, withPerspective: true}} rotationOptions={{maxX: 90, maxY: 90, withRotationLimitX: false, withRotationLimitY: false}} sensitivity={2} width={"100%"}><ComponentViewportProvider width={"188px"}><motion.div className={"framer-i3vhg7-container"} layoutDependency={layoutDependency} layoutId={"axPqzdnit-container"}><ImagensHero3D height={"100%"} id={"axPqzdnit"} layoutId={"axPqzdnit"} variant={"STvZK5BXe"} width={"100%"}/></motion.div></ComponentViewportProvider></DLook></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-y4Jtp.framer-o464c9, .framer-y4Jtp .framer-o464c9 { display: block; }", ".framer-y4Jtp.framer-1otbhbj { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-y4Jtp .framer-1nwzthi-container { flex: none; height: auto; position: relative; width: auto; }", ".framer-y4Jtp .framer-i3vhg7-container { height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-y4Jtp.framer-1otbhbj { gap: 0px; } .framer-y4Jtp.framer-1otbhbj > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-y4Jtp.framer-1otbhbj > :first-child { margin-left: 0px; } .framer-y4Jtp.framer-1otbhbj > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 408
 * @framerIntrinsicWidth 188
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerZK8Gzk8iq: React.ComponentType<Props> = withCSS(Component, css, "framer-y4Jtp") as typeof Component;
export default FramerZK8Gzk8iq;

FramerZK8Gzk8iq.displayName = "Imagens Hero";

FramerZK8Gzk8iq.defaultProps = {height: 408, width: 188};

addFonts(FramerZK8Gzk8iq, [{explicitInter: true, fonts: []}, ...ImagensHero3DFonts, ...DLookFonts], {supportsExplicitInterCodegen: true})